.react-pdf__Page__canvas{
    width: 100% !important;
}
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root{
    margin-right: 7px !important;
}
.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root{
    padding: 0px !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #aeb2ca !important; 
 border-width: 0px;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #a29a9a !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #b8bfce !important;
}

